<template>
  <div class="toast show border-gray-300">
    <div class="toast-header" :class="[ToastItem.bgHeaderColor, ToastItem.TextHeaderColor]">
      <strong class="mr-auto">{{ ToastItem.title }}</strong>
      <button type="button" class="ml-2 mb-1 close" @click="CloseToast">
        <span>&times;</span>
      </button>
    </div>
    <div class="toast-body bg-gray-200 text-black border-gray-300" v-html="ToastItem.body"></div>
  </div>
</template>

<script lang="ts">
import Toast, { IToast } from "@/tools/Toast";
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    ToastItem: Object,
  },
})
export default class ToastBarItem extends Vue {
  ToastItem!: IToast;

  CloseToast(): void {
    new Toast().RemoveToast(this.ToastItem);
  }
}
</script>


<style scoped>
:deep(ul) {
  list-style: inside;
  margin-bottom: 0;
}

:deep(li) {
  margin-top: 10px;
}
</style>