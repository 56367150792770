import { IError400 } from "./interface/IError400";
import Toast, { typeToast } from "./Toast";

export class EstruturaValidacaoDados {

    ValidarErro400(error: IError400): void {
        const _camposObrigatorios: string[] = [];

        for (const p in error.errors) {
            for (let i = 0; i < error.errors[p].length; i++) {
                const _msg: string = error.errors[p][i].toLowerCase();
                const _firstIndex = _msg.indexOf("_");
                const _cutIndex = _msg.indexOf(" ", _msg.lastIndexOf("_"));

                let _name;
                if (_msg.indexOf("dt_") == -1 && _msg.indexOf("vl_") == -1)
                    _name = this.capitalizeWords(_msg.substring(_firstIndex + 1, _cutIndex).replaceAll("_", " "));
                else
                    _name = this.capitalizeWords(_msg.substring(_firstIndex - 2, _cutIndex).replaceAll("_", " "));

                if (_msg.indexOf("is required") > 0) {
                    _camposObrigatorios.push(`O campo '${_name}' é obrigatório`);
                }
                else if (_msg.indexOf("maximum length of") > 0) {
                    // the field ds_nome_usuario must be a string or array type with a maximum length of '150'.
                    const _max = _msg.substring(_msg.indexOf("'") + 1).replaceAll("'", "").replaceAll(".", "");
                    _camposObrigatorios.push(`O ${_name} aceita o máximo de ${_max} caracteres`);
                }
                else if (_msg.indexOf("must be between") > 0) {
                    // "the field vl_conta_pagar must be between 0 and 79228162514264337593543950335."
                    const _value = _msg.substring(_msg.indexOf('between ') + 8).replaceAll('and', 'e').replaceAll('.', '');
                    if (_msg.indexOf(" vl_") > 0) {
                        _camposObrigatorios.push(`O campo 'Valor ${_name}' aceita valores entre ${_value}`);
                    }
                    else {
                        _camposObrigatorios.push(`O campo '${_name}' aceita valores entre ${_value}`);
                    }
                }
            }
        }

        if (_camposObrigatorios.length > 0) {
            new Toast().AddToast("Validação de Dados", `Erros ao salvar dados <br> <b><ul><li>${_camposObrigatorios.join("</li><li>")}</li></ul></b>`, typeToast.Error);
        }
    }

    ValidarErro400Mensagem(message: string): void {
        if (message) {
            new Toast().AddToast("Validação de Dados", message, typeToast.Error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SetarEmptyToNull(model: any): void {
        if (model) {
            for (const p in model) {
                if (typeof model[p] == "number")
                    continue;

                if (typeof model[p] == "object") {
                    this.SetarEmptyToNull(model[p]);
                }
                else {
                    if (model[p] == "") {
                        model[p] = undefined;
                    }
                }
            }
        }
    }

    private capitalizeWords(str: string): string {
        // Divide a string em palavras usando um espaço como separador
        const words = str.toLowerCase().split(" ");

        // Itera sobre cada palavra e coloca a primeira letra em maiúscula
        const capitalizedWords = words.map(word => {
            const firstLetter = word.charAt(0).toUpperCase();
            const restOfWord = word.slice(1);
            return firstLetter + restOfWord;
        });

        // Une as palavras novamente em uma string e retorna o resultado
        return capitalizedWords.join(" ");
    }
}