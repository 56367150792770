<template>
  <component :is="LayoutPage"></component>
  <toast-bar />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { defineAsyncComponent } from "vue";
// import { Autenticacao } from "./services/auth/Autenticacao";
import store from "./store";
import ToastBar from "@/components/Toast/ToastBar.vue";
import { Autenticacao } from "./services/auth/Autenticacao";

@Options({
  components: {
    SimpleLayout: defineAsyncComponent(() => import("./components/layouts/LayoutSimple.vue")),
    AppLayout: defineAsyncComponent(() => import("./components/layouts/LayoutApp.vue")),
    ToastBar,
  },
})
export default class App extends Vue {

  get LayoutPage(): string {
    return store.state.layout;
  }

  beforeCreate(): void {
    new Autenticacao().TokenUsuarioValido();
  }
}
</script>