import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4940b524"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "divContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToastBarItem = _resolveComponent("ToastBarItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ListToast, (toast) => {
      return (_openBlock(), _createBlock(_component_ToastBarItem, {
        key: toast.id,
        ToastItem: toast
      }, null, 8, ["ToastItem"]))
    }), 128))
  ]))
}