import 'vuetify/styles'

import "bootstrap/dist/css/bootstrap.min.css"
import "@/assets/css/sb-admin-2.css";
import "@/assets/css/style.css";

import "@/assets/font-awesome/css/all.css";
import "@/assets/font-awesome/css/sharp-regular.css";
import "@/assets/font-awesome/css/sharp-solid.css";

import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { vMaska } from "maska"

import { createVuetify } from 'vuetify'
import { pt } from 'vuetify/locale'
import { aliases, fa } from 'vuetify/iconsets/fa'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'pt',
    messages: { pt }
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  defaults: {
    VDataTable: {
      class: 'elevation-1'
    }
  }
});

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .component("v-form-group", defineAsyncComponent(() => import("./tools/controls/form-group.vue")))
  .component("v-tela-navegacao", defineAsyncComponent(() => import("./tools/controls/tela-navegacao.vue")))
  .component("v-decimal", defineAsyncComponent(() => import("./tools/controls/decimal.vue")))
  .directive("maska", vMaska)
  .mount("#app");
