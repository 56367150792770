import { RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [];

routes.push(
  {
    path: "/cliente",
    meta: { breadcrumb: "Módulo Cliente" },
    children: [
      {
        path: "",
        redirect: "/cliente/menu",
      },

      {
        path: "menu",
        component: () =>
          import("../views/cliente/MenuCliente.vue")
      },

      {
        path: "meuperfil",
        meta: { breadcrumb: "Dados do Meu Perfil" },
        component: () => import("../views/cliente/usuario/meu_perfil.vue")
      },

      { /**Empresa permissão admin */
        path: "empresa",
        meta: { breadcrumb: "Dados da Minha Empresa", admin: true },
        component: () => import("../views/cliente/empresa/detalhe.vue"),
      },

      { /**Pessoa */
        path: "Pessoa",
        meta: { breadcrumb: "Cliente, Fornecedor e Transportadora" },
        children: [
          {
            path: "",
            redirect: "/Cliente/Pessoa/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Pesquisa" },
            component: () => import("../views/cliente/pessoa/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo Nova Pessoa" },
            component: () => import("../views/cliente/pessoa/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            props: true,
            meta: { breadcrumb: "Editando Pessoa" },
            component: () => import("../views/cliente/pessoa/detalhe.vue"),
          },
        ],
      },

      { /**Usuarios permissão admin */
        path: "usuarios",
        meta: { breadcrumb: "Usuários", admin: true },
        children: [
          {
            path: "",
            redirect: "/cliente/usuarios/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista", admin: true },
            component: () => import("../views/cliente/usuario/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo", admin: true },
            component: () => import("../views/cliente/usuario/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando", admin: true },
            props: true,
            component: () => import("../views/cliente/usuario/detalhe.vue"),
          },
        ],
      },

      { /**Vendedor */
        path: "vendedor",
        meta: { breadcrumb: "Vendedor" },
        children: [
          {
            path: "",
            redirect: "/cliente/vendedor/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/cliente/vendedor/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/cliente/vendedor/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/cliente/vendedor/detalhe.vue"),
          },
        ],
      },

      { /**Status Pessoa Cliente */
        path: "classificacao_cliente",
        meta: { breadcrumb: "Classificação do Cliente" },
        children: [
          {
            path: "",
            redirect: "/cliente/classificacao_cliente/lista",
          },
          {
            path: "lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/cliente/status_pessoa_cliente/lista.vue"),
          },
          {
            path: "novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/cliente/status_pessoa_cliente/detalhe.vue"),
          },
          {
            path: ":id/editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/cliente/status_pessoa_cliente/detalhe.vue"),
          },
        ],
      },

      { /**Status Atendimento*/
        path: "status_atendimento",
        meta: { breadcrumb: "Status do Atendimento" },
        children: [
          {
            path: "",
            redirect: "/cliente/status_atendimento/lista",
          },
          {
            path: "lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/cliente/status_atendimento/lista.vue"),
          },
          {
            path: "novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/cliente/status_atendimento/detalhe.vue"),
          },
          {
            path: ":id/editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/cliente/status_atendimento/detalhe.vue"),
          },
        ],
      },

      { /**Atendimento */
        path: "atendimento",
        meta: { breadcrumb: "Atendimento" },
        children: [
          {
            path: "",
            redirect: "/cliente/atendimento/listapendencia",
          },
          {
            path: "listapendencia",
            meta: { breadcrumb: "Lista Pendencias / Follow-Up" },
            component: () => import("../views/cliente/atendimento/lista_pendencia_atendimento.vue"),
          },
          {
            path: "novapendencia",
            meta: { breadcrumb: "Nova Pendencias / Follow-Up" },
            component: () => import("../views/cliente/atendimento/nova_pendencia_atendimento.vue"),
          }
        ],
      },

      { /**Template de Comunicação*/
        path: "template",
        meta: { breadcrumb: "Template de Comunicação" },
        children: [
          {
            path: "",
            redirect: "/cliente/template/lista",
          },
          {
            path: "lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/cliente/template-comunicacao/lista.vue"),
          },
          {
            path: "novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/cliente/template-comunicacao/detalhe.vue"),
          },
          {
            path: ":id/editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/cliente/template-comunicacao/detalhe.vue"),
          },
        ],
      },


    ]
  },

);

export default routes;
