import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Autenticacao } from "@/services/auth/Autenticacao";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [];
import def from "./defaults";
import financeiro from "./finaneiro";
import nfe from "./nfe";
import cliente from "./cliente";
import estoque from "./estoque";
import faturamento from "./faturamento";
import fotografia from "./fotografia";

routes.push(...def);
routes.push(...financeiro);
routes.push(...nfe);
routes.push(...cliente);
routes.push(...estoque);
routes.push(...faturamento);
routes.push(...fotografia);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//-- faz a proteção das rotas
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.layout) {
    store.dispatch("triggerSetLayout", to.meta.layout);
  } else {
    store.dispatch("triggerSetLayout", "app-layout");
  }

  if (to.meta.allowAnonymous) {
    next();
    return;
  }

  if (Autenticacao.UsuarioLogado.autenticado) {
    let _valido = true;
    if (!new Autenticacao().TokenUsuarioValido())
      _valido = false;

    if (to.meta.admin) {
      const _tokenSettings = new Autenticacao().GetDataToken();
      if (_tokenSettings) {
        if (!_tokenSettings.fl_admin)
          _valido = false;
      }
    }

    if (_valido) {
      next();
      return;
    }
    else {
      next("/SemPermissao");
      return;
    }
  }

  window.location.href = "/login";
  //next("/login");
});

export default router;