import {
  IToken,
  IUsuarioAutenticado,
  IUsuarioLogadoToken,
} from "./model/IUsuarioLogado";
export * from "./model/IUsuarioLogado"

export * from "./model/IAutenticacao"
import { IDadosRefreshToken, IDadosLogin, IDefinirNovaSenha } from "./model/IAutenticacao";

import dayjs from "dayjs";
import newAxios, { AxiosError } from "axios";
import { nextTick } from "vue";

import axios from "@/tools/SettingsAxios";
import router from "@/router";


import store from "@/store";


export class Autenticacao {
  static UsuarioLogado: IUsuarioAutenticado;

  constructor() {
    Autenticacao.UsuarioLogado = { autenticado: false };
    if (localStorage.UsuarioLogado) {
      Autenticacao.UsuarioLogado = JSON.parse(
        localStorage.UsuarioLogado
      ) as IUsuarioAutenticado;
    }
  }

  /**Valida o token do usuário */
  async TokenUsuarioValido(): Promise<boolean> {
    let _tokenValido = true;

    if (!Autenticacao.UsuarioLogado?.autenticado || !Autenticacao.UsuarioLogado.token?.data_expiracao) {
      _tokenValido = false;
    } else {
      const _dataExpiracao = dayjs(Autenticacao.UsuarioLogado.token?.data_expiracao);

      if (_dataExpiracao <= dayjs()) {
        _tokenValido = false;
      }
      else if (_dataExpiracao.subtract(5, "minute") <= dayjs()) {
        _tokenValido = await Autenticacao.RefreshToken();
      }
    }

    if (!_tokenValido) {
      Autenticacao.Desconectar();
      nextTick(() => {
        router.push("/login");
      });
    }

    return _tokenValido;
  }

  static SetNovoFoto(foto64: string): void {
    if (this.UsuarioLogado.token) {
      this.UsuarioLogado.token.fotoperfil = foto64;
      this.SetUsuario(this.UsuarioLogado);
    }
  }

  /**Refresh Token */
  private static async RefreshToken(): Promise<boolean> {
    try {
      const _dadosRefreshToken = {
        accesstoken: Autenticacao.UsuarioLogado.token?.accesstoken,
        refreshtoken: Autenticacao.UsuarioLogado.token?.refreshaccesstoken,
      } as IDadosRefreshToken;

      newAxios.defaults.baseURL = process.env.VUE_APP_URL_BACKEND;
      const _tokenUsuario = await newAxios.post<IUsuarioLogadoToken>("/auth/v1/RefreshToken", _dadosRefreshToken);

      if (_tokenUsuario.status == 200) {
        this.SetUsuario({
          autenticado: true,
          token: {
            accesstoken: _tokenUsuario.data.accesstoken,
            data_criacao: _tokenUsuario.data.data_criacao,
            data_expiracao: _tokenUsuario.data.data_expiracao,
            refreshaccesstoken: _tokenUsuario.data.refreshaccesstoken,
            fotoperfil: this.UsuarioLogado.token?.fotoperfil ?? "/user.png"
          },
        });
        return true;
      }

    }
    catch {
      this.Desconectar();
    }

    return false;
  }

  /**Seta dados do usuario no localstorage */
  private static SetUsuario(_token: IUsuarioAutenticado): void {
    localStorage.setItem("UsuarioLogado", JSON.stringify(_token));
    Autenticacao.UsuarioLogado = _token;

    if (_token.token?.fotoperfil) {
      store.dispatch("triggerSetFotoPerfil", _token.token?.fotoperfil);
    }
  }

  /** Valida usuário e senha para login do sistema */
  async ValidarLogin(dadosLogin: IDadosLogin): Promise<boolean> {
    const _usuarioLogado = { autenticado: false } as IUsuarioAutenticado;
    const _tokenUsuario = await axios.post<IUsuarioLogadoToken>("/auth/v1/ValidarLogin", dadosLogin);

    if (_tokenUsuario.status == 200) {
      _usuarioLogado.autenticado = true;
      _usuarioLogado.token = _tokenUsuario.data;
    }

    Autenticacao.SetUsuario(_usuarioLogado);
    return _usuarioLogado.autenticado;
  }

  /**Faz Logoff do sistema */
  static Desconectar(): void {
    this.SetUsuario({ autenticado: false });
  }

  /**Retorna os dados internos do token */
  GetDataToken(): IToken | null {
    if (Autenticacao.UsuarioLogado.token?.accesstoken != undefined) {
      let _dataToken = Autenticacao.UsuarioLogado.token?.accesstoken.split(".")[1];
      const padding = _dataToken.length % 4;
      if (padding === 2) {
        _dataToken += '==';
      } else if (padding === 3) {
        _dataToken += '=';
      }
      const base64 = _dataToken.replace(/-/g, '+').replace(/_/g, '/');
      const bytes = new Uint8Array(atob(base64).split("").map(c => c.charCodeAt(0)));
      const utf8String = new TextDecoder("UTF8").decode(bytes);
      return JSON.parse(utf8String) as IToken;
    } else {
      return null;
    }
  }

  /**Solicitar para recuperar senha */
  async RecuperarSenha(email: string): Promise<void> {
    const config = { headers: { 'Content-Type': 'application/json' } };
    await newAxios.post("/auth/v1/EsqueciMinhaSenha", email, config);
  }


  /**Redefinir a nova senha */
  async RedefinirSenha(dados: IDefinirNovaSenha): Promise<string> {
    try {
      await newAxios.post("/auth/v1/DefinirNovaSenha", dados);
    }
    catch (ex) {
      if (ex instanceof AxiosError) {
        if (ex.status == 400) {
          return ex.message;
        }
      }
    }

    return "";
  }
}
