import { RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [];

routes.push(
  {
    path: "/faturamento",
    meta: { breadcrumb: "Módulo Faturamento" },
    children: [
      {
        path: "",
        redirect: "/faturamento/menu",
      },

      {
        path: "menu",
        component: () => import("@/views/faturamento/MenuFaturamento.vue")
      },

      { /** PedidoVenda */
        path: "PedidoVenda",
        meta: { breadcrumb: "Pedido de Venda" },
        children: [
          {
            path: "",
            redirect: "/faturamento/PedidoVenda/lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("@/views/faturamento/pedido_venda/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("@/views/faturamento/pedido_venda/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("@/views/faturamento/pedido_venda/detalhe.vue"),
          },
        ],
      },
    ]
  }
);

export default routes;
