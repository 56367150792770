import { IToast } from "@/tools/Toast";
import { createStore } from "vuex";

export default createStore({
  state: {
    layout: "app-layout",
    showWaiting: false,
    toasts: [] as IToast[],
    fotoPerfil: ""
  },
  getters: {},
  mutations: {
    SetShowWaiting(state, payload: boolean) {
      state.showWaiting = payload;
    },
    SetLayout(state, payload: string) {
      if (state.layout !== payload)
        state.layout = payload;
    },
    SetToast(state, payload: IToast) {
      let _duplicate = state.toasts.findIndex(m => m.id == payload.id);
      if (_duplicate >= 0)
        return;

      _duplicate = state.toasts.findIndex(m => m.body == payload.body && m.title == payload.title);
      if (_duplicate >= 0)
        return;

      state.toasts.push(payload);
    },
    RemoveToast(state, payload: IToast) {
      const _index = state.toasts.findIndex((a) => (a.id = payload.id));
      state.toasts.splice(_index, 1);
    },
    SetFotoPerfil(state, payload: string) {
      state.fotoPerfil = payload;
    },
  },
  actions: {
    triggerSetLayout: (context, payload: string) => {
      context.commit("SetLayout", payload);
    },
    triggerAddToast: (context, payload: IToast) => {
      context.commit("SetToast", payload);
    },
    triggerRemoveToast: (context, payload: IToast) => {
      context.commit("RemoveToast", payload);
    },
    triggerSetFotoPerfil: (context, payload: string) => {
      context.commit("SetFotoPerfil", payload);
    },
    triggerShowWaiting: (context, payload: boolean) => {
      context.commit("SetShowWaiting", payload);
    },
  },
  modules: {},
});
