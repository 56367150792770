import { RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [];

routes.push(
  {
    path: "/financeiro",
    meta: { breadcrumb: "Módulo Financeiro" },
    children: [
      {
        path: "",
        redirect: "/financeiro/menu",
      },

      {
        path: "menu",
        component: () => import("../views/financeiro/MenuFinanceiro.vue")
      },

      { /** Tipo Documento Conta */
        path: "TipoDocumentoConta",
        meta: { breadcrumb: "Tipo de Documento da Conta" },
        children: [
          {
            path: "",
            redirect: "/financeiro/TipoDocumentoConta/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/financeiro/cadastros/tipo_documento_conta/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/financeiro/cadastros/tipo_documento_conta/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/financeiro/cadastros/tipo_documento_conta/detalhe.vue"),
          },
        ],
      },

      { /**CategoriaConta */
        path: "CategoriaConta",
        meta: { breadcrumb: "Categoria da Conta" },
        children: [
          {
            path: "",
            redirect: "/financeiro/CategoriaConta/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/financeiro/cadastros/categoria/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/financeiro/cadastros/categoria/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/financeiro/cadastros/categoria/detalhe.vue"),
          },
        ],
      },

      { /**Centro de custo */
        path: "CentroCusto",
        meta: { breadcrumb: "Centro de Custo da Conta" },
        children: [
          {
            path: "",
            redirect: "/financeiro/CentroCusto/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/financeiro/cadastros/centro_custo/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/financeiro/cadastros/centro_custo/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/financeiro/cadastros/centro_custo/detalhe.vue"),
          },
        ],
      },

      { /**Conta Corrente */
        path: "ContaCorrente",
        meta: { breadcrumb: "Conta Corrente" },
        children: [
          {
            path: "",
            redirect: "/financeiro/ContaCorrente/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/financeiro/cadastros/conta_corrente/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/financeiro/cadastros/conta_corrente/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/financeiro/cadastros/conta_corrente/detalhe.vue"),
          },
        ],
      },

      { /**Contas a Pagar */
        path: "ContaPagar",
        meta: { breadcrumb: "Contas a Pagar" },
        children: [
          {
            path: "",
            redirect: "/financeiro/ContaPagar/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/financeiro/lancamentos/contapagar/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/financeiro/lancamentos/contapagar/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/financeiro/lancamentos/contapagar/detalhe.vue"),
          },
        ],
      },

      { /**Contas a Receber */
        path: "ContaReceber",
        meta: { breadcrumb: "Contas a Receber" },
        children: [
          {
            path: "",
            redirect: "/financeiro/ContaReceber/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/financeiro/lancamentos/contareceber/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/financeiro/lancamentos/contareceber/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/financeiro/lancamentos/contareceber/detalhe.vue"),
          },
        ],
      },

      { /**Fluxo de Caixa */
        path: "FluxoCaixa",
        meta: { breadcrumb: "Visualização do Fluxo de Caixa" },
        component: () => import("../views/financeiro/relatorios/fluxocaixa/pesquisa.vue"),
      },

      { /**Dashboard */
        path: "dashboard",
        meta: { breadcrumb: "Dashboard" },
        component: () => import("../views/financeiro/relatorios/dashboard/dashboard.vue"),
      },

      { /**lançamentos pendentes*/
        path: "lancamentospendentes",
        meta: { breadcrumb: "Lançamentos Pendentes" },
        component: () => import("../views/financeiro/lancamentos/pendente/lista.vue"),
      },

      { /** Importação em Lote */
        path: "ImportarContas",
        meta: { breadcrumb: "Importar Contas em Lote" },
        children: [
          {
            path: "",
            redirect: "/financeiro/ImportarContas/upload",
          },
          {
            path: "upload",
            meta: { breadcrumb: "Upload de Arquivo" },
            component: () => import("../views/financeiro/cadastros/importar_lote/uploadFile.vue"),
          },
          {
            path: "pendentes",
            meta: { breadcrumb: "Importações Pendentes de Finalização" },
            component: () => import("../views/financeiro/cadastros/importar_lote/importDetail.vue"),
          }
        ],
      },


    ]
  }
);

export default routes;
