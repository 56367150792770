import store from "@/store";
import guid from "./functions/guid";

export interface IToast {
  id: string;
  timeoutRef?: number;
  title: string;
  body: string;
  bgHeaderColor?: string;
  TextHeaderColor?: string;
  dtInclusao: Date;
}
export enum typeToast {
  Primary,
  Success,
  Info,
  Error,
  Light,
}

export default class Toast {
  AddToast(
    title: string,
    body: string,
    TypeToast: typeToast = typeToast.Light,
    timeout?: number
  ): void {
    const _toast: IToast = {
      id: guid.NewGuid(),
      title: title,
      body: body,
      dtInclusao: new Date(),
    };

    switch (TypeToast) {
      case typeToast.Primary:
        _toast.bgHeaderColor = "bg-gradient-primary";
        _toast.TextHeaderColor = "text-white";
        break;

      case typeToast.Success:
        _toast.bgHeaderColor = "bg-gradient-success";
        _toast.TextHeaderColor = "text-white";
        break;

      case typeToast.Info:
        _toast.bgHeaderColor = "bg-gradient-info";
        _toast.TextHeaderColor = "text-white";
        break;

      case typeToast.Error:
        _toast.bgHeaderColor = "bg-gradient-danger";
        _toast.TextHeaderColor = "text-white";
        break;

      case typeToast.Light:
        _toast.bgHeaderColor = "";
        _toast.TextHeaderColor = "";
        break;
    }

    if (!timeout) timeout = 8000;
    if (timeout >= 0 && timeout <= 8000) timeout = 8000;

    if (timeout >= 0) {
      _toast.timeoutRef = setTimeout(() => this.RemoveToast(_toast), timeout);
    }
    store.dispatch("triggerAddToast", _toast);
  }

  RemoveToast(toast: IToast): void {
    if (toast.timeoutRef) clearTimeout(toast.timeoutRef);
    store.dispatch("triggerRemoveToast", toast);
  }
}
