import { RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [];

routes.push(
  {
    path: "/",
    component: () => import("../views/default/HomeView.vue"),
  },
  {
    path: "/login",
    meta: { layout: "simple-layout", allowAnonymous: true },
    component: () => import("../views/default/Login.vue"),
  },
  {
    path: "/esqueciminhasenha",
    meta: { layout: "simple-layout", allowAnonymous: true },
    component: () => import("../views/default/EsqueceuSenha.vue"),
  },
  {
    path: "/redefinirsenha",
    meta: { layout: "simple-layout", allowAnonymous: true },
    component: () => import("../views/default/RedefinirSenha.vue"),
  },
  {
    path: "/SemPermissao",
    component: () => import("../views/default/SemPermissao.vue"),
  }
);

export default routes;
