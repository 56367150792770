<template>
  <!-- Position it -->
  <div class="divContainer">
    <ToastBarItem v-for="toast in ListToast" :key="toast.id" :ToastItem="toast" />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import ToastBarItem from "./ToastBarItem.vue";
import store from "@/store";
import { IToast } from "@/tools/Toast";

@Options({
  components: { ToastBarItem },
})
export default class ToastBar extends Vue {
  get ListToast(): IToast[] {
    return store.state.toasts.sort((a, b) =>
      a.dtInclusao > b.dtInclusao ? 1 : b.dtInclusao > a.dtInclusao ? -1 : 0
    );
  }
}
</script>

<style scoped>
.divContainer {
  z-index: 99999;
  top: 75px;
  right: 5px;
  position: fixed;
  overflow-x: auto;
}

:deep(.toast) {
  background-color: rgba(255, 255, 255, 0.95);
  width: 350px;
}
</style>