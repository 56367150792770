import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios";
import { Autenticacao } from "@/services/auth/Autenticacao";
import { IError400 } from "./interface/IError400";
import { EstruturaValidacaoDados } from "./EstruturaValidacaoDados";

export class SettingsAxios {
  private readonly httpClient: AxiosInstance;

  public get HttpClient(): AxiosInstance {
    return this.httpClient;
  }

  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.VUE_APP_URL_BACKEND,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      validateStatus: this.validateStatus,
    });

    this.httpClient.interceptors.request.use(
      this.beforeRequestConfig,
      this.beforeRequestError
    );

    this.httpClient.interceptors.response.use(
      this.afterResponseConfig,
      this.afterResponseError
    );
  }

  private validateStatus(status: number): boolean {
    return (
      (status >= 200 && status < 300) ||
      status === 402 || status === 401 || status === 404
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeRequestConfig(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> {
    if (Autenticacao.UsuarioLogado.autenticado) {
      if (await new Autenticacao().TokenUsuarioValido()) {
        config.headers.Authorization = `Bearer ${Autenticacao.UsuarioLogado.token?.accesstoken}`;
      } else {
        return Promise.reject();
      }
    } else {
      config.headers.Authorization = null;
    }

    if (config.method === "post" || config.method === "put") {
      new EstruturaValidacaoDados().SetarEmptyToNull(config.data);
    }

    return config;
  }

  private beforeRequestError(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  private afterResponseConfig<T>(response: AxiosResponse<T>): AxiosResponse<T> {
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private afterResponseError(error: AxiosError) {

    if (error.response && error.response.status == 400 && error.response.data) {
      const validaDados = new EstruturaValidacaoDados();
      if (typeof error.response.data === "string") {
        validaDados.ValidarErro400Mensagem(error.response.data);
      } else {
        const erro400: IError400 = error.response.data as IError400;
        validaDados.ValidarErro400(erro400);
      }
    }
    return Promise.reject(error);
  }
}

const settingsAxios = new SettingsAxios();
export default settingsAxios.HttpClient;
