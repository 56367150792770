import { RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [];

routes.push(
  {
    path: "/fotografia",
    meta: { breadcrumb: "Módulo Fotografia" },
    children: [
      {
        path: "",
        redirect: "/fotografia/menu",
      },
      {
        path: "menu",
        component: () =>
          import("../views/fotografia/MenuFotografia.vue")
      },

      { /**Escola */
        path: "escola",
        meta: { breadcrumb: "Escola" },
        children: [
          {
            path: "",
            redirect: "/fotografia/escola/Lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/fotografia/escola/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/fotografia/escola/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/fotografia/escola/detalhe.vue"),
          },
        ],
      },

      { /**Ensaio */
        path: "ensaio",
        meta: { breadcrumb: "Ensaio Escolar" },
        children: [
          {
            path: "",
            redirect: "/fotografia/ensaio/lista",
          },
          {
            path: "lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("../views/fotografia/ensaio/lista.vue"),
          },
          {
            path: "novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/fotografia/ensaio/detalhe.vue"),
          },
          {
            path: ":id/editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/fotografia/ensaio/detalhe.vue"),
          },
        ],
      },

      { /**Portfolio */
        path: "portfolio",
        meta: { breadcrumb: "Portfolio" },
        children: [
          {
            path: "",
            redirect: "/fotografia/portfolio/galeria",
          },
          {
            path: "galeria",
            meta: { breadcrumb: "Galeria" },
            component: () => import("../views/fotografia/portfolio/galeria.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("../views/fotografia/portfolio/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("../views/fotografia/portfolio/detalhe.vue"),
          },
        ],
      },

      { /**Gerar Docs e Download*/
        path: "docs-ensaio",
        meta: { breadcrumb: "Documentos do Ensaio" },
        children: [
          {
            path: "",
            redirect: "/fotografia/docs-ensaio/listadocs",
          },
          {
            path: "listadocs",
            meta: { breadcrumb: "Gerar Documentos" },
            component: () => import("../views/fotografia/ensaio/lista-gerar-docs-download.vue"),
          },
        ],
      },


      { /**Lista de Escolas para Contato*/
        path: "escola-contato",
        meta: { breadcrumb: "Controle de Contato - Escolas" },
        children: [
          {
            path: "",
            redirect: "/fotografia/escola-contato/pendencia",
          },
          {
            path: "pendencia",
            meta: { breadcrumb: "Pendencias" },
            component: () => import("../views/fotografia/escola-atendimento/lista.vue"),
          },
          {
            path: ":id/detalhe",
            meta: { breadcrumb: "Detalhe do Atendimento" },
            component: () => import("../views/fotografia/escola-atendimento/detalhe.vue"),
            props: true,
          },
        ],
      },
    ]
  },

);

export default routes;
