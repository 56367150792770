import { RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [];

routes.push(
  {
    path: "/estoque",
    meta: { breadcrumb: "Módulo Materiais / Estoque" },
    children: [
      {
        path: "",
        redirect: "/estoque/menu",
      },

      {
        path: "menu",
        component: () => import("@/views/estoque/MenuEstoque.vue")
      },

      { /** Produto */
        path: "Produto",
        meta: { breadcrumb: "Produtos" },
        children: [
          {
            path: "",
            redirect: "/estoque/produto/lista",
          },
          {
            path: "Lista",
            meta: { breadcrumb: "Lista" },
            component: () => import("@/views/estoque/produto/lista.vue"),
          },
          {
            path: "Novo",
            meta: { breadcrumb: "Incluindo" },
            component: () => import("@/views/estoque/produto/detalhe.vue"),
          },
          {
            path: ":id/Editar",
            meta: { breadcrumb: "Editando" },
            props: true,
            component: () => import("@/views/estoque/produto/detalhe.vue"),
          },
        ],
      },
    ]
  }
);

export default routes;
